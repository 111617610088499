import type { FromModel, ModelDefinition } from "@/__main__/data-model.mjs";
import createModel, { Mapped } from "@/__main__/data-model.mjs";
import { getDerivedRiotID } from "@/game-lol/utils/derived-id.mjs";
import RoleSymbol from "@/game-lol/utils/symbol-role.mjs";

export const IncomingModel = {
  average_deaths_at_10_min: Number,
  average_first_ward_time: Number,
  average_gank_deaths: Number,
  average_laning_phase_deaths: Number,
  average_laning_phase_solo_kills: Number,
  average_wards_placed: Number,
  champion_counts: Mapped({
    key: Number,
    value: Number,
  }),
  first_ganked_lane_counts: Mapped({
    key: String,
    value: Number,
  }),
  largest_critical: Number,
  largest_killing_spree: Number,
  largest_multi_kill: Number,
  recently_played_with: [
    {
      game_name: String,
      tag_line: String,
      profile_icon_id: Number,
      games: Number,
      wins: Number,
    },
  ],
  roles: Mapped({
    key: String,
    value: Number,
  }),
  total_games: Number,
  wins: Number,
} satisfies ModelDefinition;

const OutgoingModel = {
  averageDeathsAt_10Min: Number,
  averageFirstWardTime: Number,
  averageGankDeaths: Number,
  averageLaningPhaseDeaths: Number,
  averageLaningPhaseSoloKills: Number,
  averageWardsPlaced: Number,
  championCounts: [
    {
      count: Number,
      id: Number,
    },
  ],
  firstGankedLaneCounts: [
    {
      count: Number,
      id: RoleSymbol,
    },
  ],
  largestCritical: Number,
  largestKillingSpree: Number,
  largestMultiKill: Number,
  lastFewWins: [Boolean],
  lastGame: String,
  recentlyPlayedWith: [
    {
      gameName: String,
      games: Number,
      leagueProfileId: String,
      profileIconId: Number,
      summonerName: String,
      tagLine: String,
      wins: Number,
    },
  ],
  roles: [
    {
      count: Number,
      name: RoleSymbol,
    },
  ],
  teammateCounts: [
    {
      count: Number,
      id: String,
    },
  ],
  totalGames: Number,
} satisfies ModelDefinition;

const PlaystylesModel = createModel(IncomingModel).transform(
  OutgoingModel,
  (playstyles) => {
    // Convert first ganked lane counts from mapped object to array
    const firstGankedLaneCounts = Object.entries(
      playstyles.first_ganked_lane_counts,
    ).map(([id, count]) => ({
      id: RoleSymbol(id),
      count,
    }));

    // Convert roles from mapped object to array with predefined role names
    const roleNames = ["TOP", "JUNGLE", "MID", "BOTTOM", "UTILITY"];
    const roles = roleNames.map((name) => ({
      name: RoleSymbol(name),
      count: playstyles.roles[name] || 0,
    }));

    return {
      averageDeathsAt_10Min: playstyles.average_deaths_at_10_min,
      averageFirstWardTime: playstyles.average_first_ward_time,
      averageGankDeaths: playstyles.average_gank_deaths,
      averageLaningPhaseDeaths: playstyles.average_laning_phase_deaths,
      averageLaningPhaseSoloKills: playstyles.average_laning_phase_solo_kills,
      averageWardsPlaced: playstyles.average_wards_placed,
      championCounts: Object.entries(playstyles.champion_counts).map(
        ([id, count]) => ({
          id: Number(id),
          count,
        }),
      ),
      firstGankedLaneCounts,
      largestCritical: playstyles.largest_critical,
      largestKillingSpree: playstyles.largest_killing_spree,
      largestMultiKill: playstyles.largest_multi_kill,
      lastFewWins: [],
      lastGame: new Date().toISOString(),
      recentlyPlayedWith: playstyles.recently_played_with.map((player) => ({
        gameName: player.game_name,
        games: player.games,
        leagueProfileId: getDerivedRiotID(player.game_name, player.tag_line),
        profileIconId: player.profile_icon_id,
        summonerName: player.game_name,
        tagLine: player.tag_line,
        wins: player.wins,
      })),
      roles,
      teammateCounts: [], // No direct mapping available in incoming data
      totalGames: playstyles.total_games,
    };
  },
);

export type Playstyles = FromModel<typeof PlaystylesModel>;

export default PlaystylesModel;
