import type { FromModel, ModelDefinition } from "@/__main__/data-model.mjs";
import createModel, { Any, Optional, Silent } from "@/__main__/data-model.mjs";
import { getDerivedId } from "@/game-lol/utils/derived-id.mjs";
import RoleSymbol from "@/game-lol/utils/symbol-role.mjs";

export const IncomingModel = {
  info: {
    endOfGameResult: String,
    gameCreation: Number,
    gameDuration: Number,
    gameEndTimestamp: Number,
    gameId: Number,
    gameMode: String,
    gameName: String,
    gameStartTimestamp: Number,
    gameType: String,
    gameVersion: String,
    mapId: Number,
    participants: [
      {
        allInPings: Number,
        assistMePings: Number,
        assists: Number,
        baronKills: Number,
        basicPings: Number,
        bountyLevel: Number,
        challenges: Silent(Any),
        champExperience: Number,
        champLevel: Number,
        championId: Number,
        championName: String,
        championTransform: Number,
        commandPings: Number,
        consumablesPurchased: Number,
        damageDealtToBuildings: Number,
        damageDealtToObjectives: Number,
        damageDealtToTurrets: Number,
        damageSelfMitigated: Number,
        dangerPings: Number,
        deaths: Number,
        detectorWardsPlaced: Number,
        doubleKills: Number,
        dragonKills: Number,
        eligibleForProgression: Boolean,
        enemyMissingPings: Number,
        enemyVisionPings: Number,
        firstBloodAssist: Boolean,
        firstBloodKill: Boolean,
        firstTowerAssist: Boolean,
        firstTowerKill: Boolean,
        gameEndedInEarlySurrender: Boolean,
        gameEndedInSurrender: Boolean,
        getBackPings: Number,
        goldEarned: Number,
        goldSpent: Number,
        holdPings: Number,
        individualPosition: String,
        inhibitorKills: Number,
        inhibitorTakedowns: Number,
        inhibitorsLost: Number,
        item0: Number,
        item1: Number,
        item2: Number,
        item3: Number,
        item4: Number,
        item5: Number,
        item6: Number,
        itemsPurchased: Number,
        killingSprees: Number,
        kills: Number,
        lane: String,
        largestCriticalStrike: Number,
        largestKillingSpree: Number,
        largestMultiKill: Number,
        longestTimeSpentLiving: Number,
        magicDamageDealt: Number,
        magicDamageDealtToChampions: Number,
        magicDamageTaken: Number,
        missions: Silent(Any),
        neutralMinionsKilled: Number,
        nexusKills: Number,
        nexusLost: Number,
        participantId: Number,
        pentaKills: Number,
        perks: Silent({
          statPerks: {
            defense: Number,
            flex: Number,
            offense: Number,
          },
          styles: [
            {
              description: String,
              selections: [
                {
                  perk: Number,
                  var1: Number,
                  var2: Number,
                  var3: Number,
                },
              ],
              style: Number,
            },
          ],
        }),
        physicalDamageDealt: Number,
        physicalDamageDealtToChampions: Number,
        physicalDamageTaken: Number,
        placement: Number,
        playerAugment1: Number,
        playerAugment2: Number,
        playerAugment3: Number,
        playerAugment4: Number,
        playerAugment5: Number,
        playerAugment6: Number,
        playerSubteamId: Number,
        puuid: String,
        riotIdGameName: String,
        riotIdTagline: String,
        role: String,
        sightWardsBoughtInGame: Number,
        spell1Casts: Number,
        spell2Casts: Number,
        spell3Casts: Number,
        spell4Casts: Number,
        subteamPlacement: Number,
        summoner1Casts: Number,
        summoner1Id: Number,
        summoner2Casts: Number,
        summoner2Id: Number,
        summonerId: String,
        summonerLevel: Number,
        summonerName: String,
        teamEarlySurrendered: Boolean,
        teamId: Number,
        teamPosition: String,
        timeCCingOthers: Number,
        timePlayed: Number,
        totalAllyJungleMinionsKilled: Optional(Number),
        totalDamageDealt: Number,
        totalDamageDealtToChampions: Number,
        totalDamageShieldedOnTeammates: Number,
        totalDamageTaken: Number,
        totalEnemyJungleMinionsKilled: Optional(Number),
        totalHeal: Number,
        totalHealsOnTeammates: Number,
        totalMinionsKilled: Number,
        totalTimeCCDealt: Number,
        totalTimeSpentDead: Number,
        totalUnitsHealed: Number,
        tripleKills: Number,
        trueDamageDealt: Number,
        trueDamageDealtToChampions: Number,
        trueDamageTaken: Number,
        turretKills: Number,
        turretTakedowns: Number,
        turretsLost: Number,
        unrealKills: Number,
        visionScore: Number,
        visionWardsBoughtInGame: Number,
        wardsKilled: Number,
        wardsPlaced: Number,
        win: Boolean,
      },
    ],
    platformId: String,
    queueId: Number,
    teams: [
      {
        bans: [
          {
            championId: Number,
            pickTurn: Number,
          },
        ],
        feats: {
          EPIC_MONSTER_KILL: {
            featState: Number,
          },
          FIRST_BLOOD: {
            featState: Number,
          },
          FIRST_TURRET: {
            featState: Number,
          },
        },
        objectives: Silent({
          atakhan: {
            first: Boolean,
            kills: Number,
          },
          baron: {
            first: Boolean,
            kills: Number,
          },
          champion: {
            first: Boolean,
            kills: Number,
          },
          dragon: {
            first: Boolean,
            kills: Number,
          },
          horde: {
            first: Boolean,
            kills: Number,
          },
          inhibitor: {
            first: Boolean,
            kills: Number,
          },
          riftHerald: {
            first: Boolean,
            kills: Number,
          },
          tower: {
            first: Boolean,
            kills: Number,
          },
        }),
        teamId: Number,
        win: Boolean,
      },
    ],
    tournamentCode: String,
  },
  metadata: {
    dataVersion: String,
    matchId: String,
  },
} satisfies ModelDefinition;

const OutgoingModel = {
  benchmarkData: Silent(Any),
  endOfGameResult: Silent(String),
  gameCreation: Number,
  gameDuration: Number,
  gameEndTimestamp: Number,
  gameId: Number,
  gameMode: Silent(String),
  gameName: Silent(String),
  gameStartTimestamp: Number,
  gameType: Silent(String),
  gameVersion: String,
  junglePathData: Silent(Any),
  localSummonerRole: Silent(RoleSymbol),
  mapId: Number,
  participants: [
    {
      accountId: Silent(String),
      assists: Number,
      baronKills: Optional(Number),
      bountyLevel: Optional(Number),
      challenges: Silent(Any),
      champExperience: Optional(Number),
      champLevel: Number,
      championId: Number,
      championName: Silent(String),
      championTransform: Optional(Number),
      consumablesPurchased: Optional(Number),
      currentAccountId: Silent(String),
      damageSelfMitigated: Number,
      damageDealtToBuildings: Number,
      damageDealtToObjectives: Number,
      damageDealtToTurrets: Number,
      deaths: Number,
      derivedId: Silent(String),
      detectorWardsPlaced: Optional(Number),
      doubleKills: Number,
      dragonKills: Optional(Number),
      eligibleForProgression: Optional(Boolean),
      encryptedSummonerId: Silent(String),
      firstBloodAssist: Boolean,
      firstBloodKill: Boolean,
      firstInhibitorAssist: Optional(Boolean),
      firstInhibitorKill: Optional(Boolean),
      firstTowerAssist: Boolean,
      firstTowerKill: Boolean,
      gameEndedInEarlySurrender: Boolean,
      gameEndedInSurrender: Boolean,
      goldEarned: Number,
      goldSpent: Number,
      inhibitorKills: Number,
      inhibitorTakedowns: Optional(Number),
      inhibitorsLost: Optional(Number),
      individualPosition: Silent(RoleSymbol),
      isLocalPlayer: Silent(Boolean),
      item0: Number,
      item1: Number,
      item2: Number,
      item3: Number,
      item4: Number,
      item5: Number,
      item6: Number,
      killingSprees: Number,
      kills: Number,
      lane: RoleSymbol,
      largestCriticalStrike: Number,
      largestKillingSpree: Number,
      largestMultiKill: Number,
      longestTimeSpentLiving: Number,
      magicDamageDealt: Number,
      magicDamageDealtToChampions: Number,
      magicDamageTaken: Number,
      neutralMinionsKilled: Number,
      nexusKills: Optional(Number),
      nexusLost: Number,
      nexusTakedowns: Optional(Number),
      participantId: Number,
      pentaKills: Number,
      perks: Silent({
        statPerks: {
          defense: Number,
          flex: Number,
          offense: Number,
        },
        styles: [
          {
            description: String,
            selections: [
              {
                perk: Number,
                var1: Number,
                var2: Number,
                var3: Number,
              },
            ],
            style: Number,
          },
        ],
      }),
      physicalDamageDealt: Number,
      physicalDamageDealtToChampions: Number,
      physicalDamageTaken: Number,
      placement: Number,
      playerAugment1: Number,
      playerAugment2: Number,
      playerAugment3: Number,
      playerAugment4: Number,
      playerAugment5: Number,
      playerAugment6: Number,
      playerSubteamId: Number,
      puuid: Silent(String),
      quadraKills: Number,
      riotIdGameName: Silent(String),
      riotIdTagline: Silent(String),
      role: RoleSymbol,
      sightWardsBoughtInGame: Number,
      spell1Casts: Number,
      spell2Casts: Number,
      spell3Casts: Optional(Number),
      spell4Casts: Optional(Number),
      subteamPlacement: Number,
      summoner1Casts: Number,
      summoner1Id: Number,
      summoner2Casts: Number,
      summoner2Id: Number,
      summonerId: Silent(String),
      summonerLevel: Number,
      summonerName: Silent(String),
      teamEarlySurrendered: Boolean,
      teamId: Number,
      teamPosition: Silent(RoleSymbol),
      timeCCingOthers: Silent(Number),
      timePlayed: Number,
      totalAllyJungleMinionsKilled: Optional(Number),
      totalDamageDealt: Number,
      totalDamageDealtToChampions: Number,
      totalDamageShieldedOnTeammates: Number,
      totalDamageTaken: Number,
      totalEnemyJungleMinionsKilled: Optional(Number),
      totalHeal: Number,
      totalHealsOnTeammates: Number,
      totalMinionsKilled: Number,
      totalTimeCcDealt: Silent(Number),
      totalTimeSpentDead: Number,
      totalUnitsHealed: Number,
      tripleKills: Number,
      trueDamageDealt: Number,
      trueDamageDealtToChampions: Number,
      trueDamageTaken: Number,
      turretKills: Number,
      turretTakedowns: Optional(Number),
      turretsLost: Optional(Number),
      unencryptedSummonerId: Silent(Number),
      unrealKills: Number,
      visionScore: Optional(Number),
      visionWardsBoughtInGame: Number,
      wardsKilled: Optional(Number),
      wardsPlaced: Optional(Number),
      win: Boolean,
    },
  ],
  platformId: String,
  queue: Silent(String),
  queueId: Number,
  seasonId: Silent(Number),
  teams: [
    {
      bans: [
        {
          championId: Number,
          pickTurn: Number,
        },
      ],
      isWinningTeam: Silent(Boolean),
      objectives: Optional({
        atakhan: {
          first: Boolean,
          kills: Number,
        },
        baron: {
          first: Boolean,
          kills: Number,
        },
        champion: {
          first: Boolean,
          kills: Number,
        },
        dragon: {
          first: Boolean,
          kills: Number,
        },
        horde: {
          first: Boolean,
          kills: Number,
        },
        inhibitor: {
          first: Boolean,
          kills: Number,
        },
        riftHerald: {
          first: Boolean,
          kills: Number,
        },
        tower: {
          first: Boolean,
          kills: Number,
        },
      }),
      teamId: Number,
      win: Boolean,
    },
  ],
  tournamentCode: Silent(String),
} satisfies ModelDefinition;

const MatchModel = createModel(IncomingModel).transform(
  OutgoingModel,
  (match) => {
    const { info } = match;
    return {
      benchmarkData: null,
      endOfGameResult: info.endOfGameResult,
      gameCreation: info.gameCreation,
      gameDuration: info.gameDuration,
      gameEndTimestamp: info.gameEndTimestamp,
      gameId: info.gameId,
      gameMode: info.gameMode,
      gameName: info.gameName,
      gameStartTimestamp: info.gameStartTimestamp,
      gameType: info.gameType,
      gameVersion: info.gameVersion,
      junglePathData: null,
      localSummonerRole: null,
      mapId: info.mapId,
      participants: info.participants.map((p) => ({
        accountId: null,
        assists: p.assists,
        baronKills: p.baronKills,
        bountyLevel: p.bountyLevel,
        challenges: p.challenges,
        champExperience: p.champExperience,
        champLevel: p.champLevel,
        championId: p.championId,
        championName: p.championName,
        championTransform: p.championTransform,
        consumablesPurchased: p.consumablesPurchased,
        currentAccountId: null,
        damageSelfMitigated: p.damageSelfMitigated,
        damageDealtToBuildings: p.damageDealtToBuildings,
        damageDealtToObjectives: p.damageDealtToObjectives,
        damageDealtToTurrets: p.damageDealtToTurrets,
        deaths: p.deaths,
        derivedId: getDerivedId(p.riotIdGameName, p.riotIdTagline),
        detectorWardsPlaced: p.detectorWardsPlaced,
        doubleKills: p.doubleKills,
        dragonKills: p.dragonKills,
        eligibleForProgression: p.eligibleForProgression,
        encryptedSummonerId: null,
        firstBloodAssist: p.firstBloodAssist,
        firstBloodKill: p.firstBloodKill,
        firstInhibitorAssist: null,
        firstInhibitorKill: null,
        firstTowerAssist: p.firstTowerAssist,
        firstTowerKill: p.firstTowerKill,
        gameEndedInEarlySurrender: p.gameEndedInEarlySurrender,
        gameEndedInSurrender: p.gameEndedInSurrender,
        goldEarned: p.goldEarned,
        goldSpent: p.goldSpent,
        inhibitorKills: p.inhibitorKills,
        inhibitorTakedowns: p.inhibitorTakedowns,
        inhibitorsLost: p.inhibitorsLost,
        individualPosition: RoleSymbol(p.individualPosition),
        isLocalPlayer: null,
        item0: p.item0,
        item1: p.item1,
        item2: p.item2,
        item3: p.item3,
        item4: p.item4,
        item5: p.item5,
        item6: p.item6,
        killingSprees: p.killingSprees,
        kills: p.kills,
        lane: RoleSymbol(p.lane),
        largestCriticalStrike: p.largestCriticalStrike,
        largestKillingSpree: p.largestKillingSpree,
        largestMultiKill: p.largestMultiKill,
        longestTimeSpentLiving: p.longestTimeSpentLiving,
        magicDamageDealt: p.magicDamageDealt,
        magicDamageDealtToChampions: p.magicDamageDealtToChampions,
        magicDamageTaken: p.magicDamageTaken,
        neutralMinionsKilled: p.neutralMinionsKilled,
        nexusKills: p.nexusKills,
        nexusLost: p.nexusLost,
        nexusTakedowns: null,
        participantId: p.participantId,
        pentaKills: p.pentaKills,
        perks: p.perks,
        physicalDamageDealt: p.physicalDamageDealt,
        physicalDamageDealtToChampions: p.physicalDamageDealtToChampions,
        physicalDamageTaken: p.physicalDamageTaken,
        placement: p.placement,
        playerAugment1: p.playerAugment1,
        playerAugment2: p.playerAugment2,
        playerAugment3: p.playerAugment3,
        playerAugment4: p.playerAugment4,
        playerAugment5: p.playerAugment5,
        playerAugment6: p.playerAugment6,
        playerSubteamId: p.playerSubteamId,
        puuid: p.puuid,
        quadraKills: p.pentaKills,
        riotIdGameName: p.riotIdGameName,
        riotIdTagline: p.riotIdTagline,
        role: RoleSymbol(p.individualPosition),
        sightWardsBoughtInGame: p.sightWardsBoughtInGame,
        spell1Casts: p.spell1Casts,
        spell2Casts: p.spell2Casts,
        spell3Casts: p.spell3Casts,
        spell4Casts: p.spell4Casts,
        subteamPlacement: p.subteamPlacement,
        summoner1Casts: p.summoner1Casts,
        summoner1Id: p.summoner1Id,
        summoner2Casts: p.summoner2Casts,
        summoner2Id: p.summoner2Id,
        summonerId: null,
        summonerLevel: p.summonerLevel,
        summonerName: "",
        teamEarlySurrendered: p.teamEarlySurrendered,
        teamId: p.teamId,
        teamPosition: RoleSymbol(p.teamPosition),
        timeCCingOthers: p.timeCCingOthers,
        timePlayed: info.gameDuration,
        totalAllyJungleMinionsKilled: p.totalAllyJungleMinionsKilled,
        totalDamageDealt: p.totalDamageDealt,
        totalDamageDealtToChampions: p.totalDamageDealtToChampions,
        totalDamageShieldedOnTeammates: p.totalDamageShieldedOnTeammates,
        totalDamageTaken: p.totalDamageTaken,
        totalEnemyJungleMinionsKilled: p.totalEnemyJungleMinionsKilled,
        totalHeal: p.totalHeal,
        totalHealsOnTeammates: p.totalHealsOnTeammates,
        totalMinionsKilled: p.totalMinionsKilled,
        totalTimeCcDealt: p.totalTimeCCDealt,
        totalTimeSpentDead: p.totalTimeSpentDead,
        totalUnitsHealed: p.totalUnitsHealed,
        tripleKills: p.tripleKills,
        trueDamageDealt: p.trueDamageDealt,
        trueDamageDealtToChampions: p.trueDamageDealtToChampions,
        trueDamageTaken: p.trueDamageTaken,
        turretKills: p.turretKills,
        turretTakedowns: p.turretTakedowns,
        turretsLost: p.turretsLost,
        unencryptedSummonerId: null,
        unrealKills: p.unrealKills,
        visionScore: p.visionScore,
        visionWardsBoughtInGame: p.visionWardsBoughtInGame,
        wardsKilled: p.wardsKilled,
        wardsPlaced: p.wardsPlaced,
        win: p.win,
      })),
      platformId: info.platformId,
      queue: null,
      queueId: info.queueId,
      seasonId: null,
      teams: info.teams.map((team) => ({
        bans: team.bans,
        isWinningTeam: team.win,
        objectives: team.objectives,
        teamId: team.teamId,
        win: team.win,
      })),
      tournamentCode: info.tournamentCode,
    };
  },
);

export type Match = FromModel<typeof OutgoingModel>;

export default MatchModel;
