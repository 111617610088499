import { appURLs } from "@/app/constants.mjs";

export const API_V2_ROUTING = {
  // main
  BR1: appURLs.LOL,
  LA1: appURLs.LOL,
  LA2: appURLs.LOL,
  NA1: appURLs.LOL,

  // eu
  EUW1: appURLs.LOL,
  EUN1: appURLs.LOL,
  TR1: appURLs.LOL,
  ME1: appURLs.LOL,
  RU: appURLs.LOL,

  // asia
  KR: appURLs.LOL,
  JP1: appURLs.LOL,
  OC1: appURLs.LOL,
  PH2: appURLs.LOL,
  SG2: appURLs.LOL,
  TH2: appURLs.LOL,
  TW2: appURLs.LOL,
  VN2: appURLs.LOL,
};
