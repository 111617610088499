import getData from "@/__main__/get-data.mjs";
import { API_V2_ROUTING } from "@/feature-lol-rust-be/constants.mjs";
import PlaystylesModel from "@/feature-lol-rust-be/playstyles-model.mjs";
import { QUEUE_SYMBOLS } from "@/game-lol/constants/constants.mjs";
import QueueSymbol, {
  QUEUE_SYMBOL_TO_OBJECT,
} from "@/game-lol/utils/symbol-queue.mjs";
import { getDerivedId } from "@/game-lol/utils/util.mjs";

export default function ({
  region,
  gameName,
  tagLine,
  queueId,
  path,
  options = {},
}: {
  region: string;
  gameName: string;
  tagLine: string;
  queueId?: string;
  path?: string[];
  options: object;
}) {
  const p = region.toUpperCase();
  const baseURL = API_V2_ROUTING[p] || API_V2_ROUTING["NA1"];

  let queue = 0;

  if (queueId) {
    const queueSymbol =
      QueueSymbol(queueId) === QUEUE_SYMBOLS.custom
        ? QUEUE_SYMBOLS.rankedSoloDuo
        : QueueSymbol(queueId);
    queue = QUEUE_SYMBOL_TO_OBJECT[queueSymbol].id;
  }

  return getData(
    `${baseURL}/lol/playstyles/${p.toUpperCase()}/${gameName}/${tagLine}${queue ? `/${queue}` : ""}`,
    PlaystylesModel,
    path || ["lol", "playerStyle", getDerivedId(p, null, gameName, tagLine)],
    {
      ...options,
    },
  );
}
