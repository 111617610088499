import getData from "@/__main__/get-data.mjs";
import { API_V2_ROUTING } from "@/feature-lol-rust-be/constants.mjs";
import ProMatchHistoryModel from "@/feature-lol-rust-be/pro-match-history-model.mjs";
import { getProMatchlistQueryString } from "@/game-lol/utils/probuilds-utils.mjs";

export default function ({
  role = null,
  championId = null,
  path = null,
  options = {},
}: {
  role?: string;
  championId?: string | number;
  path?: string[];
  options?: object;
}) {
  const baseURL = API_V2_ROUTING["NA1"];
  const queryString = getProMatchlistQueryString(championId, role);

  return getData(
    `${baseURL}/lol/pro_match_ids?${queryString}`,
    ProMatchHistoryModel,
    path || ["lol", "proBuildMatchlist", queryString],
    {
      ...options,
    },
  );
}
