import type { TransformModelFn } from "@/__main__/data-model.mjs";
import getData from "@/__main__/get-data.mjs";
import { API_V2_ROUTING } from "@/feature-lol-rust-be/constants.mjs";
import MatchModel from "@/feature-lol-rust-be/match-model.mjs";
import { matchStatePath } from "@/game-lol/utils/match-utils.mjs";

export default function ({
  region,
  matchId,
  options = {},
}: {
  region: string;
  matchId: number;
  options: object;
}) {
  const p = region.toUpperCase();
  const baseURL = API_V2_ROUTING[p] || API_V2_ROUTING["NA1"];
  const path = matchStatePath(region, matchId, null);
  return getData(
    `${baseURL}/lol/match/${p}_${matchId}`,
    MatchModel as TransformModelFn<unknown, never>,
    path,
    {
      shouldFetchIfPathExists: false,
      ...options,
    },
  );
}
