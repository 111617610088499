import type { FromModel, ModelDefinition } from "@/__main__/data-model.mjs";
import createModel from "@/__main__/data-model.mjs";
import QueueSymbol from "@/game-lol/utils/symbol-queue.mjs";
import RoleSymbol from "@/game-lol/utils/symbol-role.mjs";

export const IncomingModel = [
  {
    assists: Number,
    champion_id: Number,
    control_wards_placed: Number,
    creep_score_at_lane_end: Number,
    creep_score_diff_at_lane_end: Number,
    damage_dealt: Number,
    damage_healed: Number,
    damage_magic_dealt: Number,
    damage_physical_dealt: Number,
    damage_self_mitigated: Number,
    damage_taken: Number,
    damage_to_champions: Number,
    damage_to_objectives: Number,
    damage_to_towers: Number,
    damage_true_dealt: Number,
    deaths: Number,
    double_kills: Number,
    first_blood: Number,
    first_inhibitor_kill: Number,
    first_played: String,
    first_tower_kill: Number,
    game_count: Number,
    game_duration: Number,
    gold_at_lane_end: Number,
    gold_diff_at_lane_end: Number,
    gold_earned: Number,
    gold_spent: Number,
    killing_sprees: Number,
    kills: Number,
    last_played: String,
    lp: Number,
    lp_games: Number,
    lp_wins: Number,
    match_ids: [String],
    minions_killed_enemy_jungle: Number,
    minions_killed_neutral: Number,
    minions_killed_team_jungle: Number,
    minions_killed_total: Number,
    penta_kills: Number,
    quadra_kills: Number,
    queue_id: QueueSymbol,
    riot_season_id: Number,
    role: RoleSymbol,
    sight_wards_placed: Number,
    time_cc_others: Number,
    total_time_cc_dealt: Number,
    triple_kills: Number,
    vision_score: Number,
    wards_killed: Number,
    wards_placed: Number,
    wards_purchased: Number,
    wins: Number,
    yellow_trinket_wards_placed: Number,
  },
] satisfies ModelDefinition;

const OutgoingModel = [
  {
    basicStats: {
      assists: Number,
      deaths: Number,
      kills: Number,
      lp: Number,
      lpEstimate: Number,
      visionScore: Number,
      wins: Number,
    },
    ccStats: {
      timeCcOthers: Number,
      totalTimeCcDealt: Number,
    },
    championId: Number,
    seasonId: Number,
    damageStats: {
      damageDealt: Number,
      damageHealed: Number,
      damageMagicDealt: Number,
      damagePhysicalDealt: Number,
      damageSelfMitigated: Number,
      damageTaken: Number,
      damageToChampions: Number,
      damageToObjectives: Number,
      damageToTowers: Number,
      damageTrueDealt: Number,
    },
    firstStats: {
      firstBlood: Number,
      firstInhibitorKill: Number,
      firstTowerKill: Number,
    },
    gameCount: Number,
    gameDuration: Number,
    goldDiffAtLaneEnd: Number,
    goldStats: {
      goldEarned: Number,
      goldSpent: Number,
    },
    lastPlayed: String,
    minionStats: {
      minionsKilledEnemyJungle: Number,
      minionsKilledNeutral: Number,
      minionsKilledTeamJungle: Number,
      minionsKilledTotal: Number,
    },
    multikillStats: {
      doubleKills: Number,
      killingSprees: Number,
      pentaKills: Number,
      quadraKills: Number,
      tripleKills: Number,
    },
    queue: QueueSymbol,
    role: RoleSymbol,
    wardStats: {
      wardsKilled: Number,
      wardsPlaced: Number,
      wardsPurchased: Number,
    },
  },
] satisfies ModelDefinition;

const ChampionsModel = createModel(IncomingModel).transform(
  OutgoingModel,
  (champions) => {
    return champions
      .map((value) => ({
        basicStats: {
          assists: value.assists,
          deaths: value.deaths,
          kills: value.kills,
          lp: value.lp,
          lpEstimate: 0,
          visionScore: value.vision_score,
          wins: value.wins,
        },
        ccStats: {
          timeCcOthers: value.time_cc_others,
          totalTimeCcDealt: value.total_time_cc_dealt,
        },
        championId: value.champion_id,
        seasonId: value.riot_season_id,
        damageStats: {
          damageDealt: value.damage_dealt,
          damageHealed: value.damage_healed,
          damageMagicDealt: value.damage_magic_dealt,
          damagePhysicalDealt: value.damage_physical_dealt,
          damageSelfMitigated: value.damage_self_mitigated,
          damageTaken: value.damage_taken,
          damageToChampions: value.damage_to_champions,
          damageToObjectives: value.damage_to_objectives,
          damageToTowers: value.damage_to_towers,
          damageTrueDealt: value.damage_true_dealt,
        },
        firstStats: {
          firstBlood: value.first_blood,
          firstInhibitorKill: value.first_inhibitor_kill,
          firstTowerKill: value.first_tower_kill,
        },
        gameCount: value.game_count,
        gameDuration: value.game_duration,
        goldDiffAtLaneEnd: value.gold_diff_at_lane_end,
        goldStats: {
          goldEarned: value.gold_earned,
          goldSpent: value.gold_spent,
        },
        lastPlayed: value.last_played,
        minionStats: {
          minionsKilledEnemyJungle: value.minions_killed_enemy_jungle,
          minionsKilledNeutral: value.minions_killed_neutral,
          minionsKilledTeamJungle: value.minions_killed_team_jungle,
          minionsKilledTotal: value.minions_killed_total,
        },
        multikillStats: {
          doubleKills: value.double_kills,
          killingSprees: value.killing_sprees,
          pentaKills: value.penta_kills,
          quadraKills: value.quadra_kills,
          tripleKills: value.triple_kills,
        },
        queue: QueueSymbol(value.queue_id),
        role: RoleSymbol(value.role),
        wardStats: {
          wardsKilled: value.wards_killed,
          wardsPlaced: value.wards_placed,
          wardsPurchased: value.wards_purchased,
        },
      }))
      .filter((champion) => {
        // TODO: REMOVE THIS
        return champion.seasonId === 15;
      });
  },
);

export type PlayerChampions = FromModel<typeof ChampionsModel>;

export default ChampionsModel;
