import getData from "@/__main__/get-data.mjs";
import { API_V2_ROUTING } from "@/feature-lol-rust-be/constants.mjs";
import MatchlistModel from "@/feature-lol-rust-be/matchlist-model.mjs";
import { getDerivedId } from "@/game-lol/utils/util.mjs";

export default function ({
  region,
  gameName,
  tagLine,
  options = {},
}: {
  region: string;
  gameName: string;
  tagLine: string;
  options: object;
}) {
  const p = region.toUpperCase();
  const baseURL = API_V2_ROUTING[p] || API_V2_ROUTING["NA1"];
  return getData(
    `${baseURL}/lol/match_list/${p.toUpperCase()}/${gameName}/${tagLine}`,
    MatchlistModel,
    ["lol", "matchlists", getDerivedId(p, null, gameName, tagLine)],
    {
      ...options,
      skipSafetyCheck: true,
    },
  );
}
