import type { FromModel, ModelDefinition } from "@/__main__/data-model.mjs";
import createModel, { Optional, Silent } from "@/__main__/data-model.mjs";
import { patchDisplay } from "@/game-lol/utils/champions-stats-utils.mjs";
import RoleSymbol from "@/game-lol/utils/symbol-role.mjs";

export const IncomingModel = [
  {
    player: {
      id: Number,
      fame: Number,
      name: String,
      region: String,
      slug: String,
      team_id: Number,
      portrait_image_url: Silent(String),
      profile_image_url: Silent(String),
      inserted_at: String,
      updated_at: String,
    },
    team: {
      id: Number,
      name: String,
      region: String,
      tag: String,
      picture_url: String,
      inserted_at: String,
      updated_at: String,
    },
    region: String,
    riotMatchId: Number,
    patch: String,
    gameDuration: Number,
    opponentChampion: Number,
    timestamp: Number,
    kills: Number,
    assists: Number,
    win: Boolean,
    deaths: Number,
    gold: Silent(Number),
    championId: Number,
    lane: String,
    role: String,
    buildPaths: [
      {
        itemId: Number,
        timestamp: Number,
      },
    ],
    items: [Number],
    runes: [Number],
    runePrimaryTree: Number,
    runeSecondaryTree: Number,
    spells: [Number],
    runeShards: [Number],
    skillOrder: [
      {
        skillSlot: Number,
        timestamp: Number,
      },
    ],
  },
] satisfies ModelDefinition;

const OutgoingModel = [
  {
    accountId: Silent(String),
    assists: Number,
    boots: [{ id: String }],
    buildPaths: [{ id: String, itemId: Number, timestamp: Number }],
    champion: Number,
    championId: Number,
    deaths: Number,
    encryptedAccountId: Silent(String),
    gameDuration: Number,
    gold: Silent(Number),
    id: String,
    insertedAt: String,
    items: [{ id: String }],
    kills: Number,
    lane: RoleSymbol,
    role: RoleSymbol,
    opponentChampion: Number,
    patch: String,
    player: {
      id: String,
      fame: Silent(Number),
      accounts: [String],
      insertedAt: String,
      name: String,
      portraitImageUrl: Silent(String),
      profileImageUrl: Silent(String),
      realName: Silent(String),
      region: String,
      slug: String,
      team: {
        id: Number,
        insertedAt: String,
        name: String,
        pictureUrl: String,
        region: String,
        tag: String,
        updatedAt: String,
      },
      teamId: Silent(String),
      updatedAt: String,
    },
    tournamentSummonerName: Optional(String),
    tournamentTeam: Optional(String),
    tournamentOpponentTeam: Optional(String),
    tournamentType: Optional(String),
    playerId: String,
    region: String,
    riotMatchId: Silent(String),

    runePrimaryTree: Number,
    runeSecondaryTree: Number,
    runeShards: [Number],
    runes: [{ id: Number }],
    skillOrder: [
      {
        id: String,
        skillSlot: Number,
        timestamp: Number,
      },
    ],
    spells: [{ ids: [Number] }],
    timestamp: Number,
    updatedAt: String,
    win: Boolean,
  },
] satisfies ModelDefinition;

const MatchlistModel = createModel(IncomingModel).transform(
  OutgoingModel,
  (matches) => {
    return matches
      .map((match) => {
        return {
          id: `${match.riotMatchId}`,
          playerId: match.player.id.toString(),
          player: {
            id: match.player.id.toString(),
            fame: match.player.fame,
            accounts: [],
            insertedAt: match.player.inserted_at,
            name: match.player.name,
            portraitImageUrl: match.player.portrait_image_url,
            profileImageUrl: match.player.profile_image_url,
            realName: "",
            region: match.player.region,
            slug: match.player.slug,
            team: {
              id: match.team.id,
              insertedAt: match.team.inserted_at,
              name: match.team.name,
              pictureUrl: match.team.picture_url,
              region: match.team.region,
              tag: match.team.tag,
              updatedAt: match.team.updated_at,
            },
            teamId: match.team.id.toString(),
            updatedAt: match.player.updated_at,
          },
          assists: match.assists,
          deaths: match.deaths,
          kills: match.kills,
          championId: match.championId,
          champion: match.championId,
          opponentChampion: match.opponentChampion,
          gameDuration: match.gameDuration,

          // Lane is the good one we care about, lane === team_position on BE
          lane: RoleSymbol(match.lane),
          role: RoleSymbol(match.lane),

          patch: patchDisplay(match.patch),
          region: match.region,
          riotMatchId: `${match.riotMatchId}`,
          runePrimaryTree: match.runePrimaryTree,
          runeSecondaryTree: match.runeSecondaryTree,
          runeShards: match.runeShards,
          runes: match.runes.map((rune) => ({ id: rune })),
          spells: [{ ids: match.spells.map((spell) => spell) }],
          timestamp: match.timestamp,
          win: match.win,
          buildPaths: match.buildPaths.map((path, index) => ({
            id: `${index}`,
            itemId: path.itemId,
            timestamp: path.timestamp,
          })),
          items: match.items.map((item) => ({ id: item.toString() })),
          boots: [],
          gold: match.gold || 0,
          insertedAt: "",
          updatedAt: "",
          accountId: "",
          encryptedAccountId: "",
          tournamentSummonerName: null,
          tournamentTeam: null,
          tournamentOpponentTeam: null,
          tournamentType: null,
          skillOrder: match.skillOrder.map((skill, index) => ({
            id: `${index}`,
            skillSlot: skill.skillSlot,
            timestamp: skill.timestamp,
          })),
        };
      })
      .filter((match) => match.gameDuration >= 900) // 15 minutes
      .sort((a, b) => {
        const aDate = new Date(a.timestamp).getTime();
        const bDate = new Date(b.timestamp).getTime();
        return bDate - aDate;
      });
  },
);

export type Matchlist = FromModel<typeof OutgoingModel>;

export default MatchlistModel;
