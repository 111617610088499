import getData from "@/__main__/get-data.mjs";
import { API_V2_ROUTING } from "@/feature-lol-rust-be/constants.mjs";
import ProfileModel from "@/feature-lol-rust-be/profile-model.mjs";
import { getDerivedId } from "@/game-lol/utils/util.mjs";

export default function ({
  region,
  gameName,
  tagLine,
  path,
  options = {},
}: {
  region: string;
  gameName: string;
  tagLine: string;
  path?: string[];
  options: object;
}) {
  const p = region.toUpperCase();

  if (!Object.keys(API_V2_ROUTING).includes(p) || !gameName || !tagLine) return;

  const baseURL = API_V2_ROUTING[p] || API_V2_ROUTING.NA1;
  return getData(
    `${baseURL}/riot_account/lol/${p.toUpperCase()}/${gameName}/${tagLine}`,
    ProfileModel,
    path || ["lol", "profiles", getDerivedId(p, null, gameName, tagLine)],
    {
      skipSafetyCheck: true,
      ...options,
    },
  );
}
