import type { FromModel, ModelDefinition } from "@/__main__/data-model.mjs";
import createModel, { Mapped, Silent } from "@/__main__/data-model.mjs";

export const IncomingModel = {
  ...Mapped({
    key: String,
    value: Silent({
      champion_id: Number,
      delta_lp: Number,
      division: String,
      lp: Number,
      match_id: String,
      queue: Number,
      role: String,
      tier: String,
      timestamp: Number,
    }),
  }),
} satisfies ModelDefinition;

const OutgoingModel = [
  {
    id: String,
    playerMatch: {
      id: String,
      playerMatchStats: {
        deltaLp: Number,
        lp: Number,
      },
    },
  },
] satisfies ModelDefinition;

const MatchlistModel = createModel(IncomingModel).transform(
  OutgoingModel,
  (matches) => {
    return Object.entries(matches)
      .map(([key, value]) => ({
        id: key,
        playerMatch: {
          id: "0",
          playerMatchStats: {
            deltaLp: value.delta_lp,
            lp: value.lp,
          },
        },
      }))
      .sort((a, b) => {
        const aID = Number(a.id.split("_")[1]);
        const bID = Number(b.id.split("_")[1]);
        return bID - aID;
      });
  },
);

export type Matchlist = FromModel<typeof OutgoingModel>;

export default MatchlistModel;
