import getData from "@/__main__/get-data.mjs";
import { API_V2_ROUTING } from "@/feature-lol-rust-be/constants.mjs";
import MatchTimelineModel from "@/feature-lol-rust-be/match-timeline-model.mjs";

export default function ({
  region,
  matchId,
  options = {},
}: {
  region: string;
  matchId: number;
  options: object;
}) {
  const p = region.toUpperCase();
  const baseURL = API_V2_ROUTING[p] || API_V2_ROUTING["NA1"];
  return getData(
    `${baseURL}/lol/timeline/${p}_${matchId}`,
    MatchTimelineModel,
    ["lol", "matchTimeline", matchId],
    {
      ...options,
      shouldFetchIfPathExists: false,
    },
  );
}
