import type { FromModel, ModelDefinition } from "@/__main__/data-model.mjs";
import createModel, { Mapped, Silent } from "@/__main__/data-model.mjs";
import QueueSymbol from "@/game-lol/utils/symbol-queue.mjs";
import { RankSymbol } from "@/game-lol/utils/symbol-rank.mjs";

const RankObject = {
  queue: QueueSymbol,
  tier: RankSymbol,
  rank: String,
  wins: Number,
  losses: Number,
  leaguePoints: Number,
  insertedAt: Date,
} satisfies ModelDefinition;

export const IncomingModel = {
  account: {
    game_name: String,
    puuid: String,
    tag_line: String,
  },
  league_lol: Mapped({
    key: String,
    value: [
      {
        league_points: Number,
        losses: Number,
        rank: String,
        rated_rating: Silent(null),
        rated_tier: Silent(null),
        tier: String,
        wins: Number,
        timestamp: Number,
      },
    ],
  }),
  league_tft: Silent(null),
  summoner: {
    profile_icon_id: Number,
    revision_date: Number,
    summoner_level: Number,
  },
} satisfies ModelDefinition;

const OutgoingModel = [RankObject] satisfies ModelDefinition;

const ProfileModel = createModel(IncomingModel).transform(
  OutgoingModel,
  (profile) => {
    return Object.entries(profile.league_lol)
      .reduce((acc, [queueStr, ranks]) => {
        const history = ranks.map((rank) => ({
          queue: QueueSymbol(queueStr),
          tier: RankSymbol(rank.tier),
          rank: rank.rank,
          wins: rank.wins,
          losses: rank.losses,
          leaguePoints: rank.league_points,
          insertedAt: new Date(rank.timestamp * 1000).toISOString(),
        }));
        return acc.concat(history);
      }, [])
      .sort(
        (a, b) =>
          new Date(b.insertedAt).getTime() - new Date(a.insertedAt).getTime(),
      );
  },
);

export type SummonerProfile = FromModel<typeof OutgoingModel>;
export type SummonerRank = FromModel<typeof RankObject>;

export default ProfileModel;
