import type { FromModel, ModelDefinition } from "@/__main__/data-model.mjs";
import createModel, { Silent } from "@/__main__/data-model.mjs";
import QueueSymbol from "@/game-lol/utils/symbol-queue.mjs";
import { RankSymbol } from "@/game-lol/utils/symbol-rank.mjs";

const RankObject = {
  queue: QueueSymbol,
  tier: RankSymbol,
  rank: String,
  wins: Number,
  losses: Number,
  leaguePoints: Number,
  insertedAt: Date,
} satisfies ModelDefinition;

export const IncomingModel = {
  account: {
    game_name: String,
    puuid: String,
    tag_line: String,
  },
  league_lol: [
    {
      league_points: Number,
      losses: Number,
      queue_type: String,
      rank: String,
      rated_rating: Silent(null),
      rated_tier: Silent(null),
      tier: String,
      wins: Number,
    },
  ],
  league_tft: Silent(null),
  summoner: {
    profile_icon_id: Number,
    revision_date: Number,
    summoner_level: Number,
  },
} satisfies ModelDefinition;

const OutgoingModel = {
  id: Silent(String),
  leagueProfileId: 0,
  accountId: String,
  puuid: String,
  summonerId: String,
  summonerName: Silent(String),
  summonerLevel: Number,
  profileIconId: Number,
  riotAccount: {
    id: String,
    puuid: String,
    gameName: String,
    tagLine: String,
  },
  updatedAt: Silent(String),
  latestRanks: Silent([RankObject]),
  ranks: Silent([RankObject]),
  derivedId: Silent(String),
  encryptedSummonerId: Silent(String),
  unencryptedSummonerId: Silent(Number),
} satisfies ModelDefinition;

const ProfileModel = createModel(IncomingModel).transform(
  OutgoingModel,
  (profile) => {
    return {
      id: "0", // No longer a thing
      leagueProfileId: 0, // No longer a thing
      accountId: profile.account.puuid,
      puuid: profile.account.puuid,
      summonerId: profile.account.puuid,
      summonerName: profile.account.game_name,
      summonerLevel: profile.summoner.summoner_level,
      profileIconId: profile.summoner.profile_icon_id,
      riotAccount: {
        id: profile.account.puuid,
        puuid: profile.account.puuid,
        gameName: profile.account.game_name,
        tagLine: profile.account.tag_line,
      },
      updatedAt: new Date(profile.summoner.revision_date).toISOString(),
      latestRanks: profile.league_lol.map((rank) => ({
        queue: QueueSymbol(rank.queue_type),
        tier: RankSymbol(rank.tier),
        rank: rank.rank,
        wins: rank.wins,
        losses: rank.losses,
        leaguePoints: rank.league_points,
        insertedAt: new Date(),
      })),
      ranks: profile.league_lol.map((rank) => ({
        queue: QueueSymbol(rank.queue_type),
        tier: RankSymbol(rank.tier),
        rank: rank.rank,
        wins: rank.wins,
        losses: rank.losses,
        leaguePoints: rank.league_points,
        insertedAt: new Date(),
      })),
      derivedId: profile.account.puuid,
      encryptedSummonerId: profile.account.puuid,
      unencryptedSummonerId: 0, // Since we don't have this in the incoming data, defaulting to 0
    };
  },
);

export type SummonerProfile = FromModel<typeof OutgoingModel>;
export type SummonerRank = FromModel<typeof RankObject>;

export default ProfileModel;
