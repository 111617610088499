// EXEMPT
import { postData } from "@/__main__/get-data.mjs";
import noopModel from "@/data-models/no-op.mjs";
import { API_V2_ROUTING } from "@/feature-lol-rust-be/constants.mjs";
import { convertRankFromNumberToRoman } from "@/game-lol/utils/rank-utils.mjs";
import { getQueueDetails } from "@/game-lol/utils/symbol-queue.mjs";
import { devError } from "@/util/dev.mjs";

export default async function postRankUpdate(params: {
  summonerId: string;
  gameName: string;
  tagLine: string;
  region: string;
  rank: string;
  tier: string;
  leaguePoints: number;
  wins: number;
  losses: number;
  season: string;
  queue: string;
}) {
  const {
    gameName,
    tagLine,
    region,
    rank,
    tier,
    leaguePoints,
    wins,
    losses,
    queue,
  } = params;

  const p = region.toUpperCase();
  const baseURL = API_V2_ROUTING[p] || API_V2_ROUTING.NA1;
  const queueId = getQueueDetails(queue)?.id;

  if (
    !Object.keys(API_V2_ROUTING).includes(p) ||
    !gameName ||
    !tagLine ||
    !queueId
  ) {
    return Promise.resolve();
  }

  const url = `${baseURL}/lol/player_standing/${p}/${gameName}/${tagLine}/${queueId}`;

  try {
    await postData(
      {
        url,
        body: JSON.stringify({
          league_points: leaguePoints,
          losses: losses,
          wins: wins,
          rank: convertRankFromNumberToRoman(rank),
          tier: tier,
        }),
        method: "PUT",
      },
      noopModel,
      undefined,
    );
  } catch (error) {
    devError("[LOL] Error sending rank update", error);
  }
}
