// EXEMPT
import { postData } from "@/__main__/get-data.mjs";
import noopModel from "@/data-models/no-op.mjs";
import { API_V2_ROUTING } from "@/feature-lol-rust-be/constants.mjs";
import { ROLE_SYMBOL_TO_RIOT_STRING } from "@/game-lol/constants/constants.mjs";
import { getQueueDetails } from "@/game-lol/utils/symbol-queue.mjs";
import RoleSymbol from "@/game-lol/utils/symbol-role.mjs";

export default function postPostmatchLP(params: {
  region: string;
  gameName: string;
  tagLine: string;
  leagueProfileId: string;
  championId: number;
  queue: string;
  role: string;
  lp: number;
  gameId: string;
  deltaLp: number;
  division: string;
  tier: string;
}) {
  const {
    region,
    gameName,
    tagLine,
    championId,
    queue,
    role,
    lp,
    gameId,
    deltaLp,
    division,
    tier,
  } = params;
  const p = region.toUpperCase();
  const baseURL = API_V2_ROUTING[p] || API_V2_ROUTING.NA1;
  const url = `${baseURL}/lol/player_match_stats/${p}/${gameName}/${tagLine}/${p}_${gameId}`;
  const queueId = getQueueDetails(queue)?.id;
  const roleSymbol = RoleSymbol(role);
  const riotRole = ROLE_SYMBOL_TO_RIOT_STRING[roleSymbol];

  if (
    !Object.keys(API_V2_ROUTING).includes(p) ||
    !gameName ||
    !tagLine ||
    !gameId ||
    !queueId ||
    !riotRole
  ) {
    return Promise.resolve();
  }

  const data = {
    queue: queueId,
    role: riotRole,
    champion_id: championId,
    lp,
    delta_lp: deltaLp,
    division,
    tier: tier,
  };
  return postData(
    {
      url,
      body: JSON.stringify(data),
      method: "PUT",
    },
    noopModel,
    undefined,
  );
}
