import type { FromModel, ModelDefinition } from "@/__main__/data-model.mjs";
import createModel, { Any, Mapped } from "@/__main__/data-model.mjs";

export const IncomingModel = {
  info: {
    endOfGameResult: String,
    frameInterval: Number,
    frames: [
      {
        events: [Any],
        participantFrames: Mapped({
          key: String,
          value: {
            championStats: {
              abilityHaste: Number,
              abilityPower: Number,
              armor: Number,
              armorPen: Number,
              armorPenPercent: Number,
              attackDamage: Number,
              attackSpeed: Number,
              bonusArmorPenPercent: Number,
              bonusMagicPenPercent: Number,
              ccReduction: Number,
              cooldownReduction: Number,
              health: Number,
              healthMax: Number,
              healthRegen: Number,
              lifesteal: Number,
              magicPen: Number,
              magicPenPercent: Number,
              magicResist: Number,
              movementSpeed: Number,
              omnivamp: Number,
              physicalVamp: Number,
              power: Number,
              powerMax: Number,
              powerRegen: Number,
              spellVamp: Number,
            },
            currentGold: Number,
            damageStats: {
              magicDamageDone: Number,
              magicDamageDoneToChampions: Number,
              magicDamageTaken: Number,
              physicalDamageDone: Number,
              physicalDamageDoneToChampions: Number,
              physicalDamageTaken: Number,
              totalDamageDone: Number,
              totalDamageDoneToChampions: Number,
              totalDamageTaken: Number,
              trueDamageDone: Number,
              trueDamageDoneToChampions: Number,
              trueDamageTaken: Number,
            },
            goldPerSecond: Number,
            jungleMinionsKilled: Number,
            level: Number,
            minionsKilled: Number,
            participantId: Number,
            position: {
              x: Number,
              y: Number,
            },
            timeEnemySpentControlled: Number,
            totalGold: Number,
            xp: Number,
          },
        }),
        timestamp: Number,
      },
    ],
    gameId: Number,
    participants: [
      {
        participantId: Number,
        puuid: String,
      },
    ],
  },
  metadata: {
    dataVersion: String,
    matchId: String,
    participants: [String],
  },
} satisfies ModelDefinition;

export const OutgoingModel = IncomingModel.info satisfies ModelDefinition;

const TimelineModel = createModel(IncomingModel).transform(
  OutgoingModel,
  (timeline) => {
    return timeline.info;
  },
);

export type Timeline = FromModel<typeof OutgoingModel>;

export default TimelineModel;
